import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/login.vue'
import index from '../views/index.vue'

Vue.use(VueRouter)

const routes = [
    { path: '/login', name: 'Login', component: Login },
   
    { path: '/exportStuPdf', component: resolve => require(['../views/01_default/export_stu.vue'], resolve) },
    { path: '/exportTchPdf', component: resolve => require(['../views/01_default/export_tch.vue'], resolve) },
    { path: '/exportTchVst', component: resolve => require(['../views/01_default/export_tch_vst.vue'], resolve) },
    { path: '/exportTchSxsp', component: resolve => require(['../views/01_default/export_tch_sxsp.vue'], resolve) },
    { path: '/exportTchSxspNew', component: resolve => require(['../views/01_default/export_tch_sxsp_new.vue'], resolve) },
    { path: '/export_unitEvaluation', component: resolve => require(['../views/01_default/export_unitEvaluation.vue'], resolve) },
    { path: '/export_zijian', component: resolve => require(['../views/01_default/export_zijian.vue'], resolve) },
    { path: '/exportStuSocialPractice', component: resolve => require(['../views/02_njcj/export_stu_social_practice.vue'], resolve) },
    { path: '/exportStuSxEvaluation', component: resolve => require(['../views/02_njcj/export_stu_sx_evaluation.vue'], resolve) },
    { path: '/exportTchLedger', component: resolve => require(['../views/wxjd/export_tch_ledger.vue'], resolve) },
    { path: '/export_wxjd_StuSxEvaluation', component: resolve => require(['../views/03_wxjd/export_stu_sx_evaluation.vue'], resolve) },
    { path: '/export_sxzj_teacher_all', component: resolve => require(['../views/02_njcj/export_sxzj_teacher_all.vue'], resolve) },
    { path: '/export_sxglcase_teacher_all', component: resolve => require(['../views/02_njcj/export_sxglcase_teacher_all.vue'], resolve) },
    { path: '/export_ckmall', component: resolve => require(['../views/ckMall/export_ckmall.vue'], resolve) },
    { path: '/export_medical_keshi_s', component: resolve => require(['../views/04_njws/keshi_s.vue'], resolve) },
    { path: '/export_medical_keshi_a', component: resolve => require(['../views/04_njws/keshi_a.vue'], resolve) },
    { path: '/export_medical_report_s', component: resolve => require(['../views/04_njws/report_s.vue'], resolve) },
    { path: '/export_medical_report_a', component: resolve => require(['../views/04_njws/report_a.vue'], resolve) },
    { path: '/export_sxal', component: resolve => require(['../views/01_default/export_sxal.vue'], resolve) },
    { path: '/export_stu_event', component: resolve => require(['../views/01_default/export_stu_event.vue'], resolve) },
    { path: '/exportTchZoufang', component: resolve => require(['../views/208_zhzz/export_tch_zoufang.vue'], resolve) },
    { path: '/exportTchIntershipLeave', component: resolve => require(['../views/208_zhzz/export_tch_intership_leave.vue'], resolve) },

    {
        path: '/index',
        name: 'index',
        component: index,
        children: []
    },
    { path: '*', redirect: "/export_zijian" },

]


const router = new VueRouter({
    base: process.env.BASE_URL,
    routes
})


//获取url参数的方法，支持vue路由各种参数写法
const $request = function (name) {
    var params = [];
    var value = null;
    if (window.location.href.indexOf(name + "=") >= 0) {
        let q = window.location.href.split("?")[1];
        if (q.indexOf("#") > 0) {
            q = q.split("#")[0];
        }
        params = q.split("&");
        for (let p of params) {
            if (p.indexOf(name + "=") >= 0) {
                value = p.replace(name + "=", "").replace(/\//g, "")
                break;
            }
        }
    }
    if (value && value != undefined && value != "undefined") {
        return value
    } else {
        return "";
    }
}


//路由进入之前检查是否登录
router.beforeEach((to, from, next) => {
    next()
    // return
    // if (window.localStorage.getItem("auth")) { //检查本地是否登录
    // 	next()
    // } else {
    // 	let auth = $request("auth"); //检查url是否带有登录字符串
    // 	if (auth) {
    // 		window.localStorage.setItem("auth", auth); //将登录字符串保存到缓存
    // 		//移除url的auth并刷新页面
    // 		let localUrl = window.location.href.replace(auth, '').replace("?auth=", '').replace("&auth=", '')
    // 		window.location.href = localUrl
    // 		window.location.reload()
    // 	} else { //跳转统一登录
    // 		if (to.path == "/login") {
    // 			next()
    // 		} else {
    // 			next({ path: "/login" })
    // 		}

    // 	}
    // }


})


export default router
