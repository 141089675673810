import Vue from 'vue'
import App from './App.vue'
import router from './router'

import Router from 'vue-router'
const routerPush = Router.prototype.push
Router.prototype.push = function push(location) {
	return routerPush.call(this, location).catch(error=> error)
}


import store from './store'
import aes from './utils/aes.js'
Vue.config.productionTip = false
import axios from "axios"
Vue.prototype.$http = axios

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);


import echarts from "echarts"
Vue.prototype.$echarts = echarts

import Print from 'vue-print-nb'
Vue.use(Print);

import '../public/css/global.css'

import _lo from 'lodash'
Vue.prototype._lo = _lo

import Moment from 'moment'
Vue.prototype.$moment = Moment

//获取用户token
let token = null;
let getToken = _ => {
	if (localStorage.getItem("auth")) {//从session中取到token后，再解密
		token = aes.decrypt(localStorage.getItem("auth"))
	}
	return token;
}

axios.interceptors.request.use(req => {

	//生成 c s f r 校验
	let v = String.fromCharCode(90 + 9) + String.fromCharCode(100 + 15) + String.fromCharCode(100 + 7 * 2) +
		String.fromCharCode(51 * 2)
	let c = {}
	let d = new Date()
	c[v] = d.getTime() + "_" + parseInt(Math.random() * 100000);
	c[v] = aes.encrypt(c[v])
	let hour = d.getHours(),
		min = d.getMinutes(),
		sec = d.getSeconds();
	hour < 10 ? hour = '0' + hour : true;
	min < 10 ? min = '0' + min : true;
	sec < 10 ? sec = '0' + sec : true;
	c["_" + v] = aes.encrypt(hour + ":" + min + ":" + sec)
	req.headers[v] = c[v] + "_" + c["_" + v]



	//todo del token
	req.headers["Authorization"] = getToken();
	if (req.url.indexOf("http") >= 0) {

	} else {
		if (window.location.href.indexOf("localhost1") > 0 || window.location.href.indexOf("192.1681") > 0) {
			req.url = "//127.0.0.1:11024" + req.url
		} else {
			//正式环境要改这个
			 req.url = "//api-saas.qushixi.org.cn" + req.url
			//req.url = "//api-saas-dev.qushixi.org.cn" + req.url
			// req.url = "http://frp.aiwx.org.cn:8555" + req.url
		}
	}
	if (req.data && !req.data.noloading) {
		// $("#loading").show();
	}
	return req
}, error => {
	return Promise.reject(error)
});

axios.interceptors.response.use(response => {

	if ((response.data&&response.data.code == 401) || (response.data&&response.data.name == 'TokenExpiredError')) {
		localStorage.clear();
		// window.location.href="/#/login"
	} else {

		if (response.data.code && response.data.code != 200) {
			// this.$message.error(response.data.msg)
			return Promise.reject(null)
		} else {
			return response
		}

	}
}, error => {
	console.log(error)
	if (error.response&&error.response.status == 401) {
		localStorage.clear();
		// window.location.href="/#/login"
	} else {
		return Promise.reject(error)
	}

})



Vue.prototype.uploadTemp = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1);
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI0Dd9MsOqnVoj',
		signature: 'Xst4hA4qiPYO5HXG/8CcQgDTm9U=',
		success_action_status: "200",

	};
	var host = 'https://mmtemp.oss-cn-hangzhou.aliyuncs.com';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {


			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 || fileName
				.indexOf(
					".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function (theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function () {
						fileObj.onSuccess({
							src: outurl + "/" + fileKey + "?s=" + this.width + "_" + this.height,
							fileName: fileObj.file.name
						})

					};
				};

			} else {
				fileObj.onSuccess({
					src: outurl + "/" + fileKey,
					fileName: fileObj.file.name
				})
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}


Vue.prototype.uploadOss = (fileObj) => {
	var myDate = new Date();
	var ossPath = 'files/' + myDate.getFullYear() + "-" + (myDate.getMonth() + 1) + "-" + myDate.getDate();
	// 获取文件后缀
	var pathArr = fileObj.file.name.split('.')
	//  随机生成文件名称
	var fileRandName = Date.now() + "" + parseInt(Math.random() * 1000)
	var fileName = fileRandName + '.' + pathArr[pathArr.length - 1]
	// 要提交的key
	var fileKey = ossPath + "/" + fileName;
	let formData = {
		name: fileObj.file.name,
		key: fileKey,
		policy: 'eyJleHBpcmF0aW9uIjoiMjAzNS0wMS0wMlQxMjowMDowMC4wMDBaIiwiY29uZGl0aW9ucyI6W1siY29udGVudC1sZW5ndGgtcmFuZ2UiLDAsMTA0ODU3NjAwMF1dfQ==',
		OSSAccessKeyId: 'LTAI4G55gyD65iSg836oWSK4',
		signature: '7a7dfIH8bpoHXTeZxd+3xGS/g54=',
		success_action_status: "200",
		app: "qsxsaas"

	};
	var host = 'https://oss.aiwx.org.cn/upload';
	var outurl = host
	var form = new FormData();
	// 其他参数
	for (let k in formData) {
		form.append(k, formData[k]);
	}
	form.append("file", fileObj.file);
	// XMLHttpRequest 对象
	var xhr = new XMLHttpRequest();
	xhr.open("post", host, true);
	xhr.upload.addEventListener("progress", fileObj.onProgress, false); //监听上传进度
	xhr.onload = (res) => {
		if (fileObj.onSuccess) {
			let resObj = res.currentTarget.responseText
			try {
				resObj = JSON.parse(resObj)
			} catch (e) {
				resObj = {}
			}
			fileName = fileName.toLocaleLowerCase();
			//计算图片高度
			if (fileName.indexOf(".jpg") > 0 || fileName.indexOf(".jpeg") > 0 || fileName.indexOf(".png") > 0 ||
				fileName
					.indexOf(
						".gif") > 0 || fileName.indexOf(".bmp") > 0) {

				var reader = new FileReader();
				reader.readAsDataURL(fileObj.file);
				reader.onload = function (theFile) {
					var image = new Image();
					image.src = theFile.target.result;
					image.onload = function () {
						if (resObj.src) {
							resObj.src += "?s=" + this.width + "_" + this.height
						}
						fileObj.onSuccess(resObj)

					};
				};

			} else {
				fileObj.onSuccess(resObj)
			}

		}
	};
	xhr.onerror = res => {
		if (fileObj.onError) {
			fileObj.onError(res)
		}

	}
	xhr.send(form);

}

function $$parent() {
	let parent = arguments[0];
	let func = arguments[1]
	let para = []
	for (let i = 2; i < arguments.length; i++) {
		para.push(arguments[i])
	}
	return new Promise(resolve => {
		let checkParent = idx => {
			if (parent.$parent && idx < 20) {
				parent = parent.$parent
				if (parent[func] !== undefined) {
					if (typeof (parent[func]) == "function") {
						parent[func](...para)
						resolve(true)
					} else {
						if (arguments.length >= 3) {//判定是赋值
							parent[func] = arguments[2]
							resolve(parent[func])
						} else {//判定是读值
							resolve(parent[func])
						}

					}
				} else {
					checkParent(idx + 1)
				}
			} else {
				console.log("未找到：" + func)
				resolve(undefined)
			}
		}
		checkParent(0)
	}, reject => { })

}

Vue.prototype.$$parent = $$parent


Vue.prototype.isJSON = (str) => {
	if (typeof str == 'string') {
		try {
			var obj=JSON.parse(str);
			if(typeof obj == 'object' && obj ){
				return true;
			}else{
				return false;
			}

		} catch(e) {
			console.log('error：'+str+'!!!'+e);
			return false;
		}
	}
}



new Vue({
	router,
	store,
	render: h => h(App)
}).$mount('#app')
